<template>
  <div class="survey">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-button @click="toggleDisplayLocale()" class="mt-2" :variant="toggleDisplayVariant()">
            Show survey in {{ displayLocaleName() }}
          </b-button>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12" md="6" class="text-md-right text-auto">
              <p class="mr-1 fluid1">Your Surveyor ID:</p>
            </b-col>
            <b-col cols="12" md="6">
              <ClickToEdit @editingModeChanged="editingModeChangedSurveyorId" extraClasses="border-danger"
                :initEdit="editSurveyId" maxlength="16" v-model="surveyorId"></ClickToEdit>
              <span class="ml-1" v-if="!editSurveyId">
                <BIconCheckCircleFill v-if="surveyorIdValid" />
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="availableCurrencies.length > 1">
        <b-col>
          <div class="mt-3">
            <label>Switch currency</label>
            <b-button @click="setCurrentCurrency(currency)" class="ml-1" v-for="(currency, idx) in availableCurrencies"
              :key="idx" :disabled="currency === currentCurrency"
              :variant="currency === currentCurrency ? 'info' : 'outline-secondary'">{{ currency }}</b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div :key="sessionId" v-if="showSurvey && serializedSurveyForm && currentCurrency">
            <survey-component ref="survey-component" survey-id="col" :locale="locale" :display-locale="displayLocale"
              referrer="" referrer2="" :session-id="sessionId" query-params="" :debug="false"
              :external-survey="serializedSurveyForm" @genericEvent="handleSurveyEvent"
              @dataChanged="handleDataChangedEvent" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions } from 'vuex';
import constants from '@/constants';
import ClickToEdit from '@/components/ClickToEdit.vue';
import { BIconCheckCircleFill } from 'bootstrap-vue';
import Utils from '../utils';

export default {
  name: 'Survey',
  components: { ClickToEdit, BIconCheckCircleFill },
  props: {
    locale: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      displayLocale: this.locale,
      surveyForm: null,
      surveyData: null,
      serializedSurveyForm: null,
      sessionId: null,
      showSurvey: true,
      surveyorId: null,
      editSurveyId: false,
    };
  },
  computed: {
    ...mapState({
      availableLocales: state => state.settings.available_locales,
      storedSurveyorId: state => state.settings.surveyor_id,
      currentCurrency: state => state.settings.current_currency,
      availableCurrencies: state => state.settings.available_currencies,
    }),
    surveyorIdValid() {
      return Utils.surveyorIdValid(this.surveyorId);
    },
  },
  methods: {
    ...mapActions({
      setSurvey: 'surveys/setSurvey',
      setInfoMessage: 'alerts/setInfoMessage',
      setSurveyorId: 'settings/setSurveyorId',
      setWarningMessage: 'alerts/setWarningMessage',
      setCurrentCurrency: 'settings/setCurrentCurrency',
      setAvailableCurrencies: 'settings/setAvailableCurrencies',
    }),
    editingModeChangedSurveyorId(mode) {
      this.editSurveyId = mode;
      this.setSurveyorId(this.surveyorId);
      if (!this.surveyorIdValid) {
        this.$nextTick(() => {
          this.editSurveyId = true;
        });
      }
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    toggleDisplayLocale() {
      this.displayLocale = this.displayLocale === this.locale ? 'MASTER' : this.locale;
    },
    toggleDisplayVariant() {
      if (this.displayLocale === this.locale) {
        return 'primary';
      }
      return 'outline-primary';
    },
    displayLocaleName() {
      if (this.displayLocale === this.locale) {
        return 'English (master)';
      }
      return this.availableLocales.find(locale => locale.value === this.locale).text;
    },
    async handleSurveyEvent(e) {
      const detail = JSON.parse(e.detail);
      if (detail.eventType === 'nextTabSelected') {
        if (detail.payload.nextTabIdx === 0) {
          // end of tabs reached.. so store the survey

          // current session key is stored in this.surveyData['session-id']
          // TODO: The handleSurveyEvent method is called twice when form has been submitted
          if (this.surveyData[constants.SESSION_ID].includes(constants.COLAPP_UUID_PREFIX)) {
            // The survey-compnent uses 'session-id' but the backend
            // expects 'session_key'. Adjust the ID so the backend sees the correct session ID.
            this.surveyData[constants.SESSION_KEY] = this.surveyData[constants.SESSION_ID];
            delete this.surveyData[constants.SESSION_ID];

            Utils.getPosition()
              .then(position => {
                const crd = position.coords;
                this.surveyData.latitude = crd.latitude;
                this.surveyData.longitude = crd.longitude;
                this.surveyData.geo_accuracy = crd.accuracy;
              })
              .catch(err => {
                console.warn(err);
                this.setWarningMessage('Could not get GEO location');
              });

            this.setSurvey(this.surveyData);
            this.setInfoMessage(constants.MSG_SURVEY_SAVED);
          }

          // create a new session id
          this.showSurvey = false;

          // this is a 'nice' hack.. just see if there's another instance
          // of the wsapp component still hanging around.. apparently it's
          // still being destroyed so we will need to wait. This should be fixed
          // in the wsapp probably but for now this seems to work ok
          while (window.SURVEYCOMPONENT) {
            // eslint-disable-next-line no-await-in-loop
            await this.timeout(10);
          }

          // take last selected region and put it in the new survey
          const region = this.surveyData.region_chooser;
          const clonedSurvey = { ...this.surveyForm };
          clonedSurvey.data.region = region;
          this.surveyForm = clonedSurvey;
          this.showSurvey = true;
        }
      }
    },

    cloneDataFromComponent(component, data) {
      let newData = {};
      if (!this.surveyData) {
        return newData;
      }
      if (component.bind && component.id && component.id in this.surveyData) {
        newData[component.bind] = this.surveyData[component.id];
      }
      if (component.components) {
        Object.values(component.components).forEach(subItem => {
          newData = { ...newData, ...this.cloneDataFromComponent(subItem, data) };
        });
      }
      return newData;
    },

    async changeSurveyCurrency() {
      const newData = {
        ...this.surveyForm.data,
        ...this.cloneDataFromComponent(this.surveyForm.view),
        currency: this.currentCurrency,
      };
      const currency = { ...this.surveyForm.currency, symbol: this.currentCurrency };
      const clonedForm = { ...this.surveyForm, data: newData, currency };
      this.surveyForm = clonedForm;
      this.surveyData = newData;

      this.showSurvey = false;
      while (window.SURVEYCOMPONENT) {
        // eslint-disable-next-line no-await-in-loop
        await this.timeout(10);
      }
      this.showSurvey = true;
    },
    handleDataChangedEvent(e) {
      const formData = e.detail;
      this.surveyData = formData;
    },
    downloadForm() {
      axios
        .get(`${process.env.VUE_APP_ENGINE_URL}/survey-json?locale=${this.locale}`)
        .then(response => {
          this.surveyForm = response.data;
          this.setAvailableCurrencies(this.surveyForm.supported_currencies);
          if (this.surveyForm.supported_currencies.length > 1) {
            this.setCurrentCurrency(null);
          } else {
            this.setCurrentCurrency(this.surveyForm.currency.symbol);
          }
        });
    },
  },
  watch: {
    // surveyorId(newValue) {
    //   if (!this.editSurveyId) {
    //     this.setSurveyorId(newValue);
    //   }
    // },
    surveyForm(newValue) {
      this.serializedSurveyForm = JSON.stringify(newValue);
      this.sessionId = `${constants.COLAPP_UUID_PREFIX}${uuidv4()}`;
      this.$nextTick(() => {
        customElements.whenDefined('survey-component').then(() => {
          const surveyComponent = this.$refs['survey-component'];
          if (surveyComponent && surveyComponent.shadowRoot) {
            const sheet = new CSSStyleSheet();
            const style = `
            .form-group .card .card-title { margin: 0!important; }
            .currency { width: 200px !important; }
          `;
            sheet.replaceSync(style);
            surveyComponent.shadowRoot.adoptedStyleSheets = [sheet];
          }
        });
      });
    },
    locale() {
      this.downloadForm();
      this.displayLocale = this.locale;
    },
    currentCurrency() {
      this.changeSurveyCurrency();
    },
  },
  mounted() {
    this.downloadForm();
    this.surveyorId = this.storedSurveyorId;
    if (!this.surveyorIdValid) {
      this.editSurveyId = true;
    }
  },
};
</script>

<style lang="scss"></style>
