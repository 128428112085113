<template>
  <div>
    <b-alert
        :show="showMissingLocaleAlert()"
        variant="warning">
      {{ missingLocaleMsg }}
    </b-alert>

    <b-alert
      :show="dismissCountDown"
      dismissible
      :variant="dismissType"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged">

      <p>{{ dismissMessage }}</p>

      <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
    </b-alert>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constants from "@/constants";
import store from '../store';

export default {
  name: 'AlertMessage',
  store,
  data() {
    return {
      dismissMessage: null,
      dismissType: null,
      dismissSecs: 10,
      dismissCountDown: 0,
      missingLocaleMsg: constants.MSG_NO_LOCALE_CONFIGURED,
      // showDismissibleAlert: false,
    };
  },
  computed: {
    ...mapState({
      message: (state) => state.alerts.message,
      messageType: (state) => state.alerts.messageType,
      locale: (state) => state.settings.current_locale,
    }),
  },
  methods: {
    ...mapActions({
      clearMessage: 'alerts/clearMessage',
      // setInfoMessage: 'alerts/setInfoMessage',
      // setWarningMessage: 'alerts/setWarningMessage',
      // setErrorMessage: 'alerts/setErrorMessage',
    }),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      if (this.message) {
        this.dismissMessage = this.message;
        this.dismissType = this.getMessageType();

        this.dismissCountDown = this.dismissSecs;
        this.clearMessage();
      }
    },
    getMessageType() {
      if (this.messageType) {
        return this.messageType;
      }
      return 'info'
    },
    showMissingLocaleAlert() {
      if (!this.locale) {
        return true;
      }
      return false;
    }
  },
  watch: {
    message: {
      handler: 'showAlert',
      immediate: true,
    },
    locale: {
      handler: 'showMissingLocaleAlert',
      immediate: true,
    }
  },
};
</script>
