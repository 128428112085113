import Vue from 'vue';
import constants from "@/constants";

const storeState = {
  surveys: {},
};

const getters = {
  surveyLength: state => Object.keys(state.surveys).length,
  surveysPresent: state => Object.keys(state.surveys).length > 0,
};

const actions = {
  clearSurveys({ commit }) {
    commit('setSurveys', {});
  },
  setSurvey({ commit }, payload) {
    commit('SET_SURVEY', payload);
  },
  setSurveys({ commit }, payload) {
    commit('SET_SURVEYS', payload);
  },
  removeSurvey({ commit }, payload) {
    commit('REMOVE_SURVEY', payload);
  },
};

const mutations = {
  SET_SURVEY(state, payload) {
    const key = payload[constants.SESSION_KEY];
    Vue.set(state.surveys, key, payload);
  },
  SET_SURVEYS(state, payload) {
    state.surveys = payload;
  },
  REMOVE_SURVEY(state, key) {
    Vue.delete(state.surveys, key);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
