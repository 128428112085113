<template>
  <div class="d-inline">
    <span @click="startEditing()" v-show="!editing">{{ value }}</span>
      <input
        v-show="editing"
        ref="input"
        :value="value"
        :maxlength="maxlength"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="editing = false"
        @blur="stopEditing()"
        type="text"
        :class="extraClasses"
        class="form-control"
      />
  </div>
</template>

<script>
export default {
  name: 'ClickToEdit',
  props: ['value', 'initEdit', 'maxlength', 'extraClasses'],
  data() {
    return {
      editing: false,
    };
  },
  watch: {
    initEdit(value) {
      if (value) {
        this.editing = value;
      }
    },
  },
  methods: {
    startEditing() {
      this.editing = true;
      this.$nextTick(() => this.$refs.input.focus());
      this.$emit('editingModeChanged', this.editing);
    },
    stopEditing() {
      this.editing = false;
      this.$emit('editingModeChanged', this.editing);
    },
  },
};
</script>
