const storeState = {
  message: null,
  messageType: null,
};

const getters = {
  showProgressBar: state => state.progressCount && state.progressMax,
};

const actions = {
  clearMessage({ commit }) {
    commit('SET_MESSAGE', null);
    commit('SET_MESSSAGE_TYPE', null);
  },
  setInfoMessage({ commit }, payload) {
    commit('SET_MESSAGE', payload);
    commit('SET_MESSSAGE_TYPE', 'info');
  },
  setWarningMessage({ commit }, payload) {
    commit('SET_MESSAGE', payload);
    commit('SET_MESSSAGE_TYPE', 'warning');
  },
  setErrorMessage({ commit }, payload) {
    commit('SET_MESSAGE', payload);
    commit('SET_MESSSAGE_TYPE', 'danger');
  },
};

const mutations = {
  SET_MESSAGE(state, payload) {
    state.message = payload;
  },
  SET_MESSSAGE_TYPE(state, payload) {
    state.messageType = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
