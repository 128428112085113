<template>
  <div class="locale-selector">
    <b-form-select
      v-if="options.length"
      v-model="selected"
      v-on:input="selectLocale"
      :options="options"
    />
    <b-spinner
      class="mr-2"
      v-if="!options.length"
      variant="light"
      label="Loading locale selector"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LocaleSelector',
  data() {
    return {
      options: [],
      supportedLocales: [],
    };
  },
  computed: {
    ...mapState({
      current_locale: (state) => state.settings.current_locale,
      available_locales: (state) => state.settings.available_locales,
      message: (state) => state.alerts.messsage,
    }),
    selected: {
      get() {
        const value = this.current_locale;
        return value || null;
      },
      set() {},
    },
  },
  methods: {
    ...mapActions({
      setCurrentLocale: 'settings/setCurrentLocale',
      setAvailableLocales: 'settings/setAvailableLocales',
      setWarningMessage: 'alerts/setWarningMessage',
      clearMessage: 'alerts/clearMessage',
    }),
    populateLocaleOptions(records) {
      records.forEach((record) => {
        const locale = record.id.toUpperCase();

        if (record.leaf && this.supportedLocales.includes(locale)) {
          this.options.push({
            value: record.id,
            text: record.translations.MASTER,
          });
        } else if (!record.leaf) {
          this.populateLocaleOptions(record.children);
        }
      });
    },
    selectLocale(value) {
      this.setCurrentLocale(value);
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_ENGINE_URL}/supported_locales`)
      .then((supportedLocalesResponse) => {
        this.supportedLocales = supportedLocalesResponse.data.supported_locales;

        axios
          .get(`${process.env.VUE_APP_WIS_API_URL}/rest/trees/locales_languagecountry/MASTER`)
          .then((response) => {
            const localeData = response.data;
            this.populateLocaleOptions(localeData.children);
            this.setAvailableLocales([...this.options]);

            this.options.sort((a, b) => (a.text > b.text ? 1 : -1));
            this.options.unshift({
              value: null,
              text: '— Select country or language —',
            });
          });
      });
  },
};
</script>

<style lang="scss" scoped>
.locale-selector {
  max-width: 20em;
}
</style>
