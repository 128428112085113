<template>
  <b-button
    class="ml-0 ml-sm-2 mr-2 btn-light"
    v-if="surveysPresent"
    v-on:click="sendSurveys"
  >
    Submit survey<span v-if="surveyLength > 1">s</span> to the WageIndicator database
    <span class="badge badge-danger">{{ surveyLength }}</span>
  </b-button>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState, mapGetters } from 'vuex';
import constants from '@/constants';

export default {
  name: 'SurveyStorage',
  computed: {
    ...mapState({
      surveys: state => state.surveys.surveys || [],
    }),
    ...mapGetters('surveys', {
      surveysPresent: 'surveysPresent',
      surveyLength: 'surveyLength',
    }),
    ...mapGetters('settings', {
      surveyorIdValid: 'surveyorIdValid',
      surveyorId: 'surveyorId',
    }),
  },
  methods: {
    ...mapActions({
      removeSurvey: 'surveys/removeSurvey',
      setInfoMessage: 'alerts/setInfoMessage',
      setErrorMessage: 'alerts/setErrorMessage',
    }),

    sendSurveys() {
      if (!this.surveyorIdValid) {
        this.setErrorMessage('Please enter your personal Surveyor ID');
        return;
      }

      const { surveyLength } = this;

      Object.entries(this.surveys).forEach(([key, payload]) => {
        const survey = {...payload}; // Prevent no-param-reassign
        survey['w20e.forms.process'] = 1; // Needed for submission in backend
        survey.surveyor_id = this.surveyorId;

        // Enable for debugging
        // See: https://webhook.site/#!/3c6297df-b973-431a-bd7e-ed0c648f4bb3/d57cb83b-d250-4e01-b73c-551f531695db/1
        // axios.post('https://webhook.site/3c6297df-b973-431a-bd7e-ed0c648f4bb3', survey);

        axios
          .post(`${process.env.VUE_APP_ENGINE_URL}/validate-survey`, survey)

          .then(() => {
            this.removeSurvey(key);

            if (this.surveyLength === 0) {
              this.setInfoMessage(
                `${constants.MSG_SURVEYS_UPLOAD_SUCCESS} (${surveyLength} total)`,
              );
            }
          })
          .catch(error => {
            console.error(error);
            this.setErrorMessage(`${constants.MSG_SURVEYS_UPLOAD_FAIL} ${error}`);
          });
      });
    },
  },
};
</script>
