<template>
  <div id="cost-of-living-app">
    <NavigationBar />

    <AlertMessage />

    <Survey v-if="showSurvey && locale" :locale="locale" />

    <Instruction v-if="!locale" />

    <NagModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import AlertMessage from '@/components/AlertMessage.vue';
import Instruction from '@/components/Instruction.vue';
import Survey from './components/Survey.vue';
import NavigationBar from './components/NavigationBar.vue';
import NagModal from './components/NagModal.vue';

export default {
  name: 'App',
  store,
  components: {
    AlertMessage,
    NavigationBar,
    Survey,
    Instruction,
    NagModal,
  },
  data() {
    return {
      showSurvey: true,
    };
  },
  computed: {
    ...mapState({
      locale: state => state.settings.current_locale,
    }),
  },
  watch: {
    locale() {
      this.reloadSurvey()
    },
  },
  methods: {
    reloadSurvey() {
      // this is a bit of a kludge: the wsapp component doesn't handle updating locales + surveys
      // very well. So as a workaround, destroy the component, (using ng-if) and recreate
      this.showSurvey = false;
      setTimeout(() => {
        this.showSurvey = true;
      }, 1);
    },

  }
};
</script>

<style lang="scss">
// Bootstrap variable overrides
//$body-bg: #000;
$primary: #238fbe;
$font-family-base: Avenir, Helvetica, Arial, sans-serif;
$font-size-base: 1.6rem;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
