<template>
  <div class="settings-modal">
    <b-button v-b-modal.settings-modal class="ml-2">
      <BIconSliders />
    </b-button>
    <b-modal id="settings-modal" ref="settings-modal" title="Settings" hide-footer>
      <b-alert show variant="info">
        <BIconExclamationTriangle />
        Clearing the local storage will not delete any stored surveys.
      </b-alert>

      <b-button variant="primary" v-on:click="cleanLocalStorage()">
        <BIconTrash />
        Clean local storage
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BIconSliders, BIconTrash, BIconExclamationTriangle } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SettingsModal',
  components: {
    BIconSliders,
    BIconTrash,
    BIconExclamationTriangle,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      surveys: (state) => state.surveys.surveys || [],
    }),
  },
  methods: {
    ...mapActions({
      setSurveys: 'surveys/setSurveys',
      setMessage: 'alerts/setMessage',
    }),
    cleanLocalStorage() {
      const storedSurveys = this.surveys;

      window.localStorage.clear();

      if (window.caches) {
        // clean service worker wisapi cache here
        // caches.delete("wis-api");
        window.caches.keys().then((names) => {
          names.forEach(name => {
            window.caches.delete(name);
          })
        });
      }

      console.log('storedSurveys', storedSurveys);
      this.setSurveys(storedSurveys);
      window.location.reload();

      // Data which is cleared in above code is not
      // reactive, reload page to show changes.
      // this.$refs['settings-modal'].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
#settings-modal___BV_modal_outer_ {
  z-index: 999999 !important;
}
</style>
