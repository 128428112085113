<template>
  <div class="nag" v-if="showModal()">

    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Cost of Living ACCEPTANCE"
        ok-title="I understand this app is for testing only"
        visible
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        ok-only
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk">

      <p>
        Welcome to the Cost of Living app, the app on this page is meant for TESTING the app.
      </p>

      <p>
        If you are here to collect prices on cost of living, please continue to
        <a href="https://costofliving.wageindicator.org/#/survey">costofliving.wageindicator.org</a>.
      </p>

      <p>
        Data collected on this ACCEPTANCE app is NOT saved in the Cost of Living database.
      </p>

      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            :label="`To continue on this app, please type '${ expectedName }' in the field below`"
            label-for="name-input"
            :invalid-feedback="`Text '${ expectedName }'`"
            :state="nameState">
          <b-form-input
              id="name-input"
              v-model="name"
              v-on:click="noNagPlus"
              :state="nameState"
              required
          ></b-form-input>

        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NagModal',
  data() {
    return {
      name: '',
      nameState: null,
      expectedName: 'testing only',
      noNag: 0,
    }
  },
  computed: {
    ...mapState({
      show_nag_modal: (state) => state.settings.show_nag_modal,
    }),
  },
  methods: {
    ...mapActions({
      hideNagModal: 'settings/hideNagModal',
    }),
    showModal() {
      if (process.env.VUE_APP_SHOW_NAG_MODAL === '1' && this.show_nag_modal) {
        return true;
      }
      return false;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid

      const input = this.name;
      if (valid && input && input.toLowerCase().trim() === this.expectedName) {
        return true;
      }
      return false
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
    noNagPlus() {
      this.noNag += 1;
    },
    checkNoNag() {
      if (this.noNag > 10) {
        this.hideNagModal();
        this.$bvModal.hide('modal-prevent-closing');
      }
    },
  },
  watch: {
    noNag: {
      handler: 'checkNoNag',
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
#modal-prevent-closing___BV_modal_outer_ {
  z-index: 999999 !important;
}
</style>
