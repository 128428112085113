<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="primary"
    >
      <b-navbar-brand href="#">
        <img
          src="/img/spheres.png"
          class="d-inline-block align-top mr-2"
          alt="WageIndicator.org logo"
          height="30"
        />
        <b-container fluid="xs">
          <b-row>
            <b-col>{{ brandName }}</b-col>
            <b-col>{{ appTitle }}</b-col>
          </b-row>
        </b-container>
      </b-navbar-brand>

      <b-navbar-nav
        class="mr-auto ml-md-2 ml-0"
        v-if="surveyLength"
      >
        <li>
          <SurveyStorage />
        </li>
      </b-navbar-nav>

      <b-navbar-toggle
        target="nav-collapse"
        class="ml-auto"
      ></b-navbar-toggle>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <li>
            <LocaleSelector />
          </li>
          <li>
            <SettingsModal />
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { BNavbar, BNavbarBrand } from 'bootstrap-vue';
import { mapState, mapGetters } from 'vuex';
import LocaleSelector from './LocaleSelector.vue';
import SettingsModal from './SettingsModal.vue';
import SurveyStorage from './SurveyStorage.vue';

export default {
  name: 'NavigationBar',
  components: {
    BNavbar,
    BNavbarBrand,
    LocaleSelector,
    SettingsModal,
    SurveyStorage,
  },
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND_NAME,
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
  computed: {
    ...mapState({
      surveys: (state) => state.surveys.surveys,
    }),
    ...mapGetters('surveys', {
      surveyLength: 'surveyLength',
    }),
  },
};
</script>

<style lang="scss">
// Styling when menu is opened via hamburger menu
.navbar-collapse.collapse.show {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .navbar-nav {
    display: inline-flex;
    flex-direction: row;
  }
}

a.navbar-brand {
  display: flex;
  .col {
    padding-right: 0;
  }
}
</style>
