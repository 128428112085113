<template>
  <b-card tag="article" class="mb-2">
    <b-card-title>
      <img src="/img/grocery.svg" class="d-inline-block align-top mr-1" alt="Cost of Living app" height="30">
      Cost of Living Survey
    </b-card-title>
    <b-card-sub-title class="mb-2">Please be patient while data is loading...</b-card-sub-title>

    <b-card-text>
      <b>Instructions</b>
      <ul>
        <li>Select the country (plus language) for which you want to fill in the survey</li>
        <li>Then after loading the country regions select the appropriate region</li>
        <li>
          You can fill in the survey in English or in the language you selected. This doesn't impact
          the survey, but it might be easier for the person filling in the survey to see the English
          translations. Toggle between the language via the button above the region chooser
        </li>
        <li>Fill in the survey (skip the items you don't know)</li>
        <li>Submit the survey (on the last tab an the bottom you will find the submit button)</li>
        <li>Finally, upload the stored surveys to the WageIndicator database</li>
      </ul>

      <p>
        <em>
          Note that you can use this survey offline, after you have selected the country and the
          regions have been loaded. After this you can go offline (flightmode if you want) and fill
          in multiple surveys. Note that you need to be online to upload the surveys
        </em>
      </p>

      <p>
        Once in a while you might want to remove the local storage (via the setings button on the
        top right) This will not throw away your stored surveys, but it will reload the survey
        questions and region data These questions might be updated once in a while.
      </p>
      <p>
        Find your results each quater at
        <a href="https://wageindicator.org/salary/living-wage"
          >wageindicator.org/salary/living-wage</a
        >
      </p>
      <p>
        More? Send a message to
        <a href="mailto:office@wageindicator.org">office@wageindicator.org</a>
      </p>
      <sup>© <a href="https://wageindicator.org">WageIndicator.org</a> {{ year() }} - App icon made by <a href="https://www.flaticon.com/authors/pongsakornred" title="pongsakornRed">pongsakornRed</a> from <a href="https://www.flaticon.com/free-icon/grocery_3731110" title="Flaticon">www.flaticon.com</a></sup>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'Instruction',
  components: {},
  methods: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
