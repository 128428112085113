import Utils from '@/utils';

const storeState = {
  current_locale: null,
  available_locales: [],
  current_currency: null,
  available_currencies: [],
  show_nag_modal: true,
  surveyor_id: null,
};

const getters = {
  surveyorIdValid: state => Utils.surveyorIdValid(state.surveyor_id),
  surveyorId: state => state.surveyor_id,
};

const actions = {
  clearLocale({ commit }) {
    commit('SET_CURRENT_LOCALE', null);
  },
  clearAvailableLocales({ commit }) {
    commit('SET_AVAILABLE_LOCALES', []);
  },
  setCurrentLocale({ commit }, payload) {
    commit('SET_CURRENT_LOCALE', payload);
  },
  setAvailableLocales({ commit }, payload) {
    commit('SET_AVAILABLE_LOCALES', payload);
  },
  PUSH_AVAILABLE_LOCALES({ commit }, payload) {
    commit('PUSH_AVAILABLE_LOCALES', payload);
  },

  clearCurrency({ commit }) {
    commit('SET_CURRENT_CURRENCY', null);
  },
  clearAvailableCurrencies({ commit }) {
    commit('SET_AVAILABLE_CURRENCIES', []);
  },
  setCurrentCurrency({ commit }, payload) {
    commit('SET_CURRENT_CURRENCY', payload);
  },
  setAvailableCurrencies({ commit }, payload) {
    commit('SET_AVAILABLE_CURRENCIES', payload);
  },
  PUSH_AVAILABLE_CURRENCIES({ commit }, payload) {
    commit('PUSH_AVAILABLE_CURRENCIES', payload);
  },

  hideNagModal({ commit }) {
    commit('SET_NAG_MODAL', false);
  },
  setSurveyorId({ commit }, payload) {
    commit('SET_SURVEYOR_ID', payload);
  },
};

const mutations = {
  SET_CURRENT_LOCALE(state, payload) {
    state.current_locale = payload;
  },
  SET_AVAILABLE_LOCALES(state, payload) {
    state.available_locales = payload;
  },
  PUSH_AVAILABLE_LOCALES(state, payload) {
    state.available_locales.push(payload);
  },

  SET_CURRENT_CURRENCY(state, payload) {
    state.current_currency = payload;
  },
  SET_AVAILABLE_CURRENCIES(state, payload) {
    state.available_currencies = payload;
  },
  PUSH_AVAILABLE_CURRENCIES(state, payload) {
    state.available_currencies.push(payload);
  },

  SET_NAG_MODAL(state, payload) {
    state.show_nag_modal = payload;
  },
  SET_SURVEYOR_ID(state, payload) {
    state.surveyor_id = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
