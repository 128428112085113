export default class Utils {
  static luhnCheck = value => {
    if (/[^0-9]+/.test(value)) return false;

    let nCheck = 0;
    let bEven = false;

    for (let n = value.length - 1; n >= 0; n -= 1) {
      const cDigit = value.charAt(n);
      let nDigit = parseInt(cDigit, 10);

      if (bEven) {
        nDigit *= 2;
        if (nDigit > 9) {
          nDigit -= 9;
        }
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  };

  static surveyorIdValid(surveyorId) {
    // Should be exactly 16 digits, not starting with a 0 and pass the luhn check
    // wih a new 'period' of surveys we create new allowed numbers
    // they all should start with 6 (randmonly generated) numbers

    // Define the lookup table with dates and prefixes (IN ORDER!!!!)
    const prefixLookup = [
      { date: new Date('2026-04-01'), prefix: '#NOPE#' },      
      { date: new Date('2026-01-01'), prefix: '105863' },
      { date: new Date('2025-10-01'), prefix: '862173' },
      { date: new Date('2025-07-01'), prefix: '138650' },
      { date: new Date('2025-04-01'), prefix: '785216' },
      { date: new Date('2025-01-01'), prefix: '532018' },
      { date: new Date('2024-10-01'), prefix: '640783' },
      { date: new Date('2024-07-01'), prefix: '516734' },
      { date: new Date('2024-04-01'), prefix: '270153' },
      { date: new Date('2024-01-01'), prefix: '546782' },
      { date: new Date('2023-10-01'), prefix: '675824' },
      { date: new Date('2023-07-01'), prefix: '714237' },
      { date: new Date('2023-04-01'), prefix: '#NOPE#' },
    ];

    const currentDate = new Date();
    // Find the matching prefix based on the current date
    const { prefix } = prefixLookup.find(({ date }) => currentDate >= date) || prefixLookup[prefixLookup.length - 1];

    if (!surveyorId) {
      return false;
    }
    if (surveyorId.length !== 16) {
      return false;
    }
    if (surveyorId.slice(0, 6) !== prefix) {
      return false;
    }
    return Utils.luhnCheck(surveyorId);
  }

  static getPosition() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options),
    );
  }
}
