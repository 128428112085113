import Vue from 'vue';
import Vuex from 'vuex';
import './registerServiceWorker';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);

new Vue({
  render: h => h(App),
}).$mount('#app');
